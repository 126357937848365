import { CogIcon, CopyIcon, FileTextIcon, ListEndIcon } from "lucide-react";
import type { ZudokuConfig } from "zudoku";
import { EnterpriseFeature } from "./src/EnterpriseFeature";
import { GithubButton } from "./src/GithubButton";
import { navigation } from "./src/navigation";
import { PolicyOverview } from "./src/PolicyOverview";
import ZupIt from "./src/ZupIt.tsx";
import { HeadNavigation } from "./src/HeadNavigation";

const iconStyle = { display: "inline", verticalAlign: "-0.125em" };

const mdxComponents = {
  Screenshot: (props: any) => <img {...props} />,
  GithubButton,
  ZupIt: (props: any) => <ZupIt {...props} />,
  CodeEditorTabIcon: () => <FileTextIcon style={iconStyle} size={18} />,
  SettingsTabIcon: () => <CogIcon style={iconStyle} size={18} />,
  CopyIcon: () => <CopyIcon style={iconStyle} size={18} />,
  EnvironmentVariablePicker: () => (
    <ListEndIcon
      style={{ display: "inline", verticalAlign: "-0.125em" }}
      size={18}
    />
  ),
  PolicyOverview,
  EnterpriseFeature,
};

const config: ZudokuConfig = {
  page: {
    pageTitle: "Zuplo Docs",
    logoUrl: "https://cdn.zuplo.com/www/favicon.png",
  },
  metadata: {
    title: "%s - Zuplo Docs",
  },
  redirects: [
    {
      from: "/",
      to: "/docs",
    },
    {
      from: "/docs",
      to: "/docs/articles/what-is-zuplo",
    },
  ],
  theme: {
    dark: {
      primary: "315.53 100% 50%",
      background: "0 0% 0%",
      border: "216 28.74% 17.06",
    },
    light: {
      primary: "316 91% 45%",
      background: "0 0 100%",
      border: "220 13.04% 90.98%",
    },
  },
  slotlets: {
    "head-navigation-start": HeadNavigation,
  },
  search: {
    type: "inkeep",
    apiKey: "499c156cf7a9798343949c8bb5665ac95e48132c6d68c42e",
    integrationId: "clot3asdz0000s601nc8jwnzx",
    organizationId: "org_dDOlt2uJlMWM8oIS",
    primaryBrandColor: "#ff00bd",
    organizationDisplayName: "Zuplo",
  },
  topNavigation: [
    {
      id: "docs",
      label: "Documentation",
    },
    {
      id: "api",
      label: "API Reference",
    },
  ],
  sidebar: {
    docs: navigation,
  },

  mdx: {
    components: mdxComponents,
  },
  apis: {
    type: "url",
    input: "https://developer-api-main-5b4bb7c.d2.zuplo.dev/openapi",
    navigationId: "/api",
  },
  docs: {
    files: "/src/pages/**/*.mdx",
  },
};

export default config;
